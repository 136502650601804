import "./footer-component.css";
import logoImage from '../../images/footer-nocturno-logo.svg'
import phoneIcon from '../../images/phone-logo.svg'
import mailIcon from '../../images/mail-logo.svg'
import locationIcon from '../../images/location-logo.svg'
import youtubeIcon from '../../images/youtube-icon.svg'
import instagramIcon from '../../images/instagram-icon.svg'
import whatsupIcon from '../../images/whatsup-logo.svg'

const FooterComponent = () => {
    return (
        <div className="footer-component" >
          <div className="left-section">
            <img src={logoImage} alt="Logo of Nocturno Creative FPV company" />
            </div>
            <div className="middle-section">
            <div className="text-row">
                <img src={mailIcon} alt="Mail Icon" className="icon" />
                <p>info@nocturno-creative.com</p>
            </div>
            <div className="text-row">
                <img src={phoneIcon} alt="Phone Icon" className="icon" />
                <p>+385 95 918 7347</p>
            </div>
            <div className="text-row">
                <img src={locationIcon} alt="Location Icon" className="icon" />
                <p>Based in Split, Croatia - Operating world wide</p>
            </div>
            </div>
            <div className="right-section">
                <a href="https://youtube.com/@nocturnofpv"><img src={youtubeIcon} alt="Youtube" /></a>
                <a href="https://www.instagram.com/nocturnofpv/"><img src={instagramIcon} alt="Instagram" /></a>
                <a href="https://wa.link/dsvme8"><img src={whatsupIcon} alt="Whatsup" /></a>
            </div>
        </div>
      );
};

export default FooterComponent;