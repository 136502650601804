import "./home-welcome-components.css";
import headerVideo from '../../images/promo-web-nocturno.mp4'
import headerVideoMobile from '../../images/promo-web-nocturno-mobile.mp4'
import {isMobile} from 'react-device-detect';

import React, { useRef } from 'react';

const HomeWelcomeComponent = (props) => {
    let headerTextColor = "#ffffff"

    let videoToShow = isMobile ? headerVideoMobile : headerVideo

    const targetSection = useRef(null);

    // Function to handle the scroll action
    const scrollToSection = () => {
      targetSection.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className="home-welcome">
          <div className="welcome-image">
            <div className="video-container">
                <video autoPlay loop muted playsInline>
                    <source src={videoToShow} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="color-overlay"></div>
            </div>
          </div>
          <div className="welcome-header-center">
            <h1 style={{color: headerTextColor}}>{props.title}</h1>
            <p style={{color: headerTextColor}}>{props.desc}</p>
          </div>

          <div className="icon-container" >
            <div className="icon">
              <div className="scroll" onClick={scrollToSection} ></div>
            </div>
          </div>

          <div ref={targetSection}></div>

        </div>
      );
};

export default HomeWelcomeComponent;
